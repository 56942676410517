









































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsCollectionReminder
  from '@/vue-app/components/contract-savings/collection/ContractSavingsCollectionReminder.vue';
import ContractSavingsReady
  from '@/vue-app/components/contract-savings/Ready.vue';
import ContractSavingsCollectionFinishCollectionViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-collection-finish-collection-view-model';

@Component({
  name: 'ContractSavingsCollectionFinishCollection',
  components: {
    ContractSavingsCollectionReminder,
    ContractSavingsReady,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsCollectionFinishCollection extends Vue {
  finish_collection_view_model = Vue.observable(
    new ContractSavingsCollectionFinishCollectionViewModel(),
  );

  @Watch('finish_collection_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  updateInitialContributions() {
    this.$emit('updateStep', 13);
  }

  nextStep() {
    if (this.finish_collection_view_model.internal_step === 2) {
      this.$emit('nextStep');
    } else if (this.finish_collection_view_model.internal_step === 1) {
      if (this.finish_collection_view_model.recurring_contribution_amount) {
        this.finish_collection_view_model.internal_step += 1;
      } else {
        this.$emit('nextStep');
      }
    }
  }

  updateRecurringContributions() {
    this.$emit('updateStep', 14);
  }

  async created() {
    this.finish_collection_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.finish_collection_view_model.initialize();
  }
}

